"use client"
import { Box, Chip, Link, Typography, alpha } from "@mui/material"
import { usePathname } from "next/navigation"

function Banner() {
    const pathname = usePathname()

    if (pathname !== "/") return null

    return (
        <Link
            href="/post/recall-fundraising-announcement-2024"
            sx={{
                textDecoration: "none",
                color: "inherit",
                width: "100%",
                display: "block",
                "&:hover": {
                    backgroundColor: (theme) => alpha(theme.palette.primary.dark, 0.9),
                    transition: "background-color 0.2s ease-in-out",
                },
            }}
        >
            <Box
                component="div"
                sx={{
                    width: "100%",
                    padding: 1,
                    px: 2,
                    pr: 3,
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                    backgroundColor: (theme) => theme.palette.primary.dark,
                }}
            >
                <Chip
                    label="Announcement"
                    size="small"
                    sx={{
                        px: 1,
                        display: { xs: "none", md: "inline-flex" },
                    }}
                />
                <Typography variant="h6" fontWeight={600} fontSize={{ xs: 12, md: 16 }}>
                    Recall raises $1.5M pre-seed round! Read our story here.
                </Typography>
            </Box>
        </Link>
    )
}

export default Banner
